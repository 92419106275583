*{
    margin: 0;
    padding: 0;
}
  
html,
body {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
  
body {
    background-color: #000000;
}
  
.container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    background-image: radial-gradient(
        #430908,
        #240807
    );
}
  
.circle-container {
    $particleNum: 100;

    position: absolute;
    transform: translateY(-10vh);
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    .circle {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        mix-blend-mode: difference ;
        background-image: radial-gradient(
        hsl(32, 100%, 50%) 40%,
        hsl(36, 100%, 83%) 30%,
        hsla(54, 92%, 90%, 0) 60%
        );

        animation: fade-frames 250ms infinite, scale-frames 3000ms infinite;

        @keyframes fade-frames {
        0% {
            opacity: 1;
        }

        50% {
            opacity: 0.7;
        }

        100% {
            opacity: 1;
        }
        }

        @keyframes scale-frames {
        0% {
            transform: scale3d(0.4, 0.4, 1);
        }

        50% {
            transform: scale3d(2.2, 2.2, 1);
        }

        100% {
            transform: scale3d(0.4, 0.4, 1);
        }
        }
    }

    $particleBaseSize: 5;

    @for $i from 1 through $particleNum {
        &:nth-child(#{$i}) {
        $circleSize: random($particleBaseSize);
        width: $circleSize + px;
        height: $circleSize + px;

        $startPositionY: random(10) + 100;
        $framesName: "move-frames-" + $i;
        $moveDuration: 30000 + random(9000) + ms;

        animation-name: #{$framesName};
        animation-duration: $moveDuration;
        animation-delay: random(30000) + ms;

        @keyframes #{$framesName} {
            from {
            transform: translate3d(
                #{random(100) + vw},
                #{$startPositionY + vh},
                0
            );
            }

            to {
            transform: translate3d(
                #{random(100) + vw},
                #{- $startPositionY - random(30) + vh},
                0
            );
            }
        }

        .circle {
            animation-delay: random(2000) + ms;
        }
        }
    }
}
  